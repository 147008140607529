.side-painel {
  padding: 15px 10px;
  float: left;
  min-width: 280px;
  max-width: 340px;
  width: 40%;
  height: 100%;
  background: #2c3e50;
  color: #f5f5f5;
  overflow: hidden;
  position: relative;
}

.content {
  float: right;
  width: 60%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: rgba(205, 205, 205, 0.065);
  padding: 0;
  @media screen and (max-width: 735px) {
    width: calc(100% - 58px);
    min-width: 300px !important;
  }

  @media screen and (min-width: 900px) {
    width: calc(100% - 340px);
  }
}
