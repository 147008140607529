.log {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 11px !important;
  padding: 0;
  font-size: italic;
  font-weight: 500;
}

//Melhorar isto depois
.message {
  .icon-img {
    margin: 6px 0 0 8px;
    float: right;
  }

  .box {
    margin-top: 10px;
    min-width: 200px;
    display: flex;
    align-items: flex-end;
    gap: 5px;
  }

  p {
    border-radius: 10px;
    padding: 10px;
  }
}

.reply {
  .box {
    justify-content: start;
  }

  p {
    background: #f5f5f5;
  }
}

.sent {
  .box {
    justify-content: end;
  }

  p {
    *{
      text-align: right;
    }
    // color: #f5f5f5;
    background: #f5f5f5;
  }
}
