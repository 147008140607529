.chat-list-content {
  .search-filter {
    padding-left: 26.5px !important;
  }

  .status-online {
    color: #2ecc71;
    position: absolute;
    margin-top: -30px;
    margin-left: -20px;
  }
}
