.main {
  .image-attached {
    height: 60px;
    background-color: #fff;
    img {
      height: 60px;
    }
    p {
      font-weight: 300;
      color: #dfdfdfce;
      width: 100%;
      transition: 0.1s;
      :hover {
        color: #dfdfdfef;
      }
    }
  }

  .message-box {
    height: 434px;
    overflow: scroll;
    padding-bottom: 100px;
  }

  .message-content {
    background-color: #dfdfdf;
    position: absolute;
    width: 100%;
    bottom: 0;
    ul {
      background-color: #2c3e50 !important;
      min-width: 15px;
      li {
        button,
        label {
          color: rgb(253, 253, 253) !important;
        }
      }
    }

    button {
      border-radius: 4px;
      padding: 8.5px 10px !important;
      border: none;
    }
  }
}
